.overviewSection {
    height: 100vh;
    background: url('/public/TravelImageLanding.png') no-repeat center center fixed;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overviewText {
    font-family: 'Roboto', sans-serif;
    text-shadow: 2px 2px 2px black;
    color: rgb(227, 40, 157);
    text-align: center;
    max-width: 80%;
}

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {
    .overviewText h1 {
        font-size: 8em;
    }

    .overviewText h2 {
        font-size: 4em;
    }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
    .overviewText h1 {
        font-size: 5em;
    }

    .overviewText h2 {
        font-size: 2.5em;
    }
}

